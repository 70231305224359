import Nav from "react-bootstrap/Nav";

import "./Footer.css";

export default function Footer() {
  const customizeCookies = () => {
    if (window.shortbread) {
      window.shortbread.customizeCookies();
    }
  };

  return (
    <div className="Footer">
      <div className="container">
      <div className="row">
      <div className="col-md-8">
      <Nav defaultActiveKey="/home" as="ul">
        <Nav.Item as="li">
          <Nav.Link
            href="https://anitab.org/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item as="li">
          <Nav.Link
            href="https://ghc.anitab.org/event-terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Site terms
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link onClick={customizeCookies}>Cookie preferences</Nav.Link>
        </Nav.Item> */}
        <Nav.Item as="li">
          <Nav.Link href="https://ghc.anitab.org/event-terms-and-conditions" target="_blank">Terms & Conditions</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link href="https://anitab.org/terms-of-use/?_gl=1*1f7pkk1*_ga*MjE5OTUyNjQ0LjE2ODU5OTgwMjY.*_ga_370K3Z436K*MTY4ODEzNTUzNy4zLjAuMTY4ODEzNTUzNy4wLjAuMA.." target="_blank">Terms of Use</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
        © 2023, AnitaB.org or its affiliates. All rights reserved
        </Nav.Item>
      </Nav>
      </div>
      <div className="col-md-4">
      <ul class="social-nav">
        <Nav.Item as="li">
          <Nav.Link
            href="https://twitter.com/anitab_org"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fab fa-twitter"><span class="sr-only">Visit us on Twitter</span></i>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            href="https://www.linkedin.com/company/anitab-org/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fab fa-linkedin-in"><span class="sr-only">Visit us on LinkedIn</span></i>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            href="https://www.instagram.com/anitab_org/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fab fa-instagram"><span class="sr-only">Visit us on Instagram</span></i>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            href="https://www.facebook.com/AnitaB.0rg/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fab fa-facebook-f"><span class="sr-only">Visit us on Facebook</span></i>
          </Nav.Link>
        </Nav.Item>
        
      </ul>
      </div>
    </div>
    </div>
    </div>
  );
}